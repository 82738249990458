import React, { Fragment, useState, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import CartIcon from 'src/components/CartIcon'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import CustomAnimatedIcon from 'src/components/CustomAnimatedIcon'
import MobileMenu from 'src/components/MobileMenu'
import RoughEdge from 'src/components/RoughEdge'
import NavUnderline from 'src/assets/images/nav-underline.svg'
import { decodeProductId } from 'src/utils/format'

import { OverlayContext } from 'src/state/OverlayState'
import { ShopifyContext } from 'src/state/ShopifyState'
import { colors, typography, animations, mq, util } from 'src/styles'

const showHide = false // show and hide header on scroll
export const headerHeight = (attr = 'height', additionalHeight = 0) =>
	util.responsiveStyles(
		attr,
		110 + additionalHeight,
		110 + additionalHeight,
		100 + additionalHeight,
		75 + additionalHeight
	)
export const headerHeightCollapsed = (attr = 'height') =>
	util.responsiveStyles(attr, 76, 70, 66, 60)

const Dropdown = styled.ul`
	list-style: none;
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 200px;
	background: ${ colors.mainColor };
	color: ${ colors.bgColor };
	${ typography.bodySmall };
	font-weight: 600;
	letter-spacing: 0;
	padding: 10px 16px;
	visibility: hidden;
	opacity: 0;
	transition: visibility ${ animations.mediumSpeed } ease-in-out,
		opacity ${ animations.mediumSpeed } ease-in-out;
	a {
		display: inline-block;
		padding: 3px 0;
		opacity: 0.6;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			left: 100%;
			width: 5px;
			height: 5px;
			transform: rotate(-45deg);
			border-bottom: 2px solid;
			border-right: 2px solid;
			border-color: ${ colors.mainColor };
			top: 50%;
			margin-top: -3px;
			opacity: 0;
			transition: transform ${ animations.mediumSpeed } ease-in-out,
				opacity ${ animations.mediumSpeed } ease-in-out;
		}
		&:hover {
			opacity: 1;
			&:after {
				opacity: 1;
				transform: rotate(-45deg) translate3d(5px, 5px, 0);
			}
		}
	}
`

const NavLinkUnderline = styled(NavUnderline)`
	display: block;
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 0;
	width: 100%;
	overflow: visible;
	height: 0.25em;
	* {
		fill: none;
		stroke-width: 1.5px;
		stroke: ${ colors.mainColor };
		stroke-dasharray: 200%;
		stroke-dashoffset: 200%;
		transition: stroke-dashoffset ${ animations.mediumSpeed } ease-in-out,
			stroke ${ animations.mediumSpeed } ease-in-out;
	}
	${ ({ active }) =>
		active ? `
		* {
			stroke-dasharray: 200%;
			stroke-dashoffset: 40%;
		}
	` : '' }
`

const NavLinkStyle = (scrolled, active, hasAtf, dropdown) => `
	display: block;
	position: relative;
	${ typography.smallCaps }
	line-height: 1em;
	transition:   padding ${ animations.mediumSpeed } ease-in-out,
								margin ${ animations.mediumSpeed } ease-in-out,
								background ${ animations.mediumSpeed } ease-in-out,
								opacity ${ animations.mediumSpeed } ease-in-out,
								color ${ animations.mediumSpeed } ease-in-out;
	color: inherit;
	${
		hasAtf ? `
		 ~ ${ NavLinkUnderline } {
			* {
				stroke: currentcolor;
			}
		}
	` : ''
	}
	
	&:hover {
		~ ${ NavLinkUnderline } {
			* {
				stroke-dasharray: 200%;
				stroke-dashoffset: 40%;
			}
		}
	}

	${
		scrolled &&
		`
		~ ${ NavLinkUnderline } {
			* {
				stroke: ${ colors.mainColor };
			}
		}
		${
			!active &&
			`
			&:hover {
				opacity: 1;
			}
		`
		}
	`
	}
`

const NavLink = styled(Link)`
	${ props =>
		NavLinkStyle(props.scrolled, props.active, props.hasAtf, props.hasDropdown) }
`

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
`

const StyledRoughEdge = styled(RoughEdge)`
	path {
		fill: ${ colors.white };
	}
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
	opacity: ${ ({ scrolled, mobileMenuOpen }) =>
		scrolled && !mobileMenuOpen ? 1 : 0 };
`

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	transition: height ${ animations.mediumSpeed } ease-in-out,
		background ${ animations.mediumSpeed } ease-in-out,
		transform ${ animations.mediumSpeed } ease-in-out,
		box-shadow ${ animations.mediumSpeed } ease-in-out;
	${ ({ scrolled, hasAtf, mobileMenuOpen }) =>
		scrolled ? `
		${ headerHeightCollapsed() }
		background: ${ colors.white };
		color: ${ colors.textColor };
		box-shadow: 0 0 30px ${ rgba(colors.textColor, 0.1) };
		${ mq.mediumAndBelow } {
			${
				mobileMenuOpen ? `
				background: transparent;
				box-shadow: none;
				${ headerHeight() }
			` : `
			`
			}
		}
	` : `
		${ headerHeight() }
		background: transparent;
		${
			hasAtf && !mobileMenuOpen ? `
			color: ${ colors.white };
		` : `
			color: ${ colors.textColor };
		`
		}
	` }
	${ ({ navVisible }) =>
		navVisible &&
		`
		transform: translate3d(0, -101%, 0);
	` }
`

const HeaderContent = styled(Grid)`
	transition: padding ${ animations.mediumSpeed } ease-in-out;
	${ ({ hasBanner }) =>
		hasBanner ? `
		${ util.responsiveStyles('padding-top', 6, 6, 4, 2) }
	` : '' }
`

const HeaderLogo = styled(Logo)`
	${ util.responsiveStyles('width', 112, 104, 98, 80) }
	height: auto;
	transition: color ${ animations.mediumSpeed } ease-in-out,
		width ${ animations.mediumSpeed } ease-in-out;
	svg {
		margin-top: -4%;
		display: block;
	}
	${ ({ scrolled, hasAtf, mobileMenuOpen }) =>
		scrolled ? `
		color: inherit;
		${ util.responsiveStyles('width', 90, 80, 80, 80) }
		${ mq.mediumAndBelow } {
			${
				mobileMenuOpen ? `
				${ util.responsiveStyles('width', 112, 104, 98, 80) }
			` : ''
			}
		}
	` : '' }
`

const LogoCol = styled.div`
	text-align: center;
	a {
		display: inline-block;
		vertical-align: top;
		transition: none;
		max-width: 100%;
	}
`

const NavLinks = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	align-items: center;
	display: flex;
	width: 100%;
	justify-content: ${ ({ alignment }) =>
		alignment === 'right' ? 'flex-end' : 'flex-start' };
	li {
		position: relative;
		flex-shrink: 0;
		&:hover {
			${ Dropdown } {
				visibility: visible;
				opacity: 1;
			}
		}
		&:not(:last-of-type) {
			${ util.responsiveStyles('margin-right', 60, 40, 20, 20) }
		}
	}
`

const MenuIcon = styled.li`
	display: none;
	margin-left: -10px;
	margin-right: -10px;
	cursor: pointer;
	display: block;
	vertical-align: top;
	transition: color ${ animations.mediumSpeed } ease-in-out;
	button {
		padding: 5px 10px;
		display: block;
		outline: none;
		border: none;
		background: transparent;
		appearance: none;
		color: inherit;
	}
	span {
		display: block;
	}
`

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	transition: height ${ animations.mediumSpeed } ease-in-out;
	${ headerHeight() }
`

const HeaderNotificationBanner = styled(NotificationBanner)`
	position: relative;
	z-index: 5;
	${ ({ hidden }) =>
		hidden &&
		`
		opacity: 0;
	` }
`

const Header = ({
	location,
	hasAtf = false,
	bannerText,
	collapsed,
	bannerColor,
	navigation,
	rightNavigation,
	title,
	placeholder = true,
	cartLink
}) => {
	const [bannerVisible, toggleBanner] = useState(true)

	const { allShopifyProduct } = useStaticQuery(
		graphql`
			query {
				allShopifyProduct {
			    edges {
			      node {
			        handle
			        shopifyId
			      }
			    }
			  }
			}
		`
	)

	const getProductLink = id => {
		const decodedId = decodeProductId(id)
		const product = allShopifyProduct?.edges?.filter(product => product.node.shopifyId === decodedId)[0]?.node
		return '/shop/' + product?.handle
	}

	const {
		mobileMenu,
		toggleMobileMenu,
		closeMobileMenu,
		toggleCart
	} = useContext(OverlayContext)

	const pathname = location
	const pageHasAtf = hasAtf && !mobileMenu

	const allNavigation = navigation.concat(rightNavigation)

	const {
		store: { checkout },
	} = useContext(ShopifyContext)

	let cartCount = 0

	if (checkout.lineItems.length > 0) {
		checkout.lineItems.forEach(item => {
			cartCount = cartCount + item.quantity
		})
	}

	const bannerTextRaw = JSON.parse(bannerText?.raw)
	let hasBanner = true

	if (bannerTextRaw?.content[0]?.content[0]?.value === '' || !bannerText || !bannerTextRaw) {
		hasBanner = false
	}

	return (
		<Fragment>
			{hasBanner && (
				<div>
					<HeaderNotificationBanner
						closeBanner={() => toggleBanner(false)}
						collapsed={!bannerVisible}
						text={bannerText}
						hidden
					/>
				</div>
			)}
			<ScrollListener.Consumer>
				{({
					scrolledToTop,
					scrolledToBottom,
					scrollY,
					scrolledUp,
					hasScrolled,
					pageHeight,
				}) => {
					let scrolled = false
					if (collapsed) {
						scrolled = true
					} else {
						scrolled = !scrolledToTop && hasScrolled
					}
					return (
						<Wrapper
							hasAtf={pageHasAtf}
							navVisible={!scrolledUp && !scrolledToTop && showHide}
						>
							{hasBanner && (
								<div>
									<HeaderNotificationBanner
										closeBanner={() => toggleBanner(false)}
										collapsed={!bannerVisible}
										text={bannerText}
										setTheme={bannerColor}
									/>
								</div>
							)}
							<HeaderWrapper
								navVisible={!scrolledUp && !scrolledToTop && showHide}
								hasAtf={pageHasAtf}
								scrolled={scrolled}
								mobileMenuOpen={mobileMenu}
							>
								<HeaderContent
									small="1 [3] [6] [3] 1"
									medium="1 [10] [4] [10] 1"
									large="1 [8] [8] [8] 1"
									larger="1 [12] [12] [12] 1"
									extraLarge="1 [16] [16] [16] 1"
									vAlign="center"
									hasAtf={pageHasAtf}
									navVisible={!scrolledUp && !scrolledToTop && showHide}
									hasBanner={bannerText && bannerVisible}
								>
									<div>
										<NavLinks alignment="left">
											<ResponsiveComponent
												small={
													<li>
														<MenuIcon id="mobile-menu-icon">
															<button
																onClick={() => toggleMobileMenu(!mobileMenu)}
																aria-label="Toggle Navigation"
															>
																<CustomAnimatedIcon mobileMenuOpen={mobileMenu} />
															</button>
														</MenuIcon>
													</li>
												}
												medium={
													navigation &&
													navigation.map((link, index) => {
														let to = '/'
														let active = false
														if (link?.to[0]?.shopifyProductId) {
															to = getProductLink(link?.to[0]?.shopifyProductId)
														} else if (link.externalLink) {
															to = link.externalLink
														} else if (link?.to) {
															to = '/' + link.to[0].slug
															active = pathname === link.to[0].slug
														}
														return (
															<li key={'header-link-' + link.id}>
																<NavLink
																	scrolled={scrolled}
																	hasAtf={pageHasAtf}
																	to={to}
																	active={active}
																	key={to}
																	hasDropdown={link.dropdownLinks}
																>
																	{link.label}
																</NavLink>
																<NavLinkUnderline
																	active={active}
																/>
																{link.dropdownLinks && (
																	<Dropdown>
																		{link.dropdownLinks.map(
																			(dropdownLink, index) => (
																				<li key={dropdownLink.id}>
																					<Link
																						to={'/' + dropdownLink.to[0].slug}
																					>
																						{dropdownLink.label}
																					</Link>
																				</li>
																			)
																		)}
																	</Dropdown>
																)}
															</li>
														)
													})
												}
											/>
										</NavLinks>
									</div>
									<LogoCol>
										<Link to="/" title={title}>

											<a onClick={() => closeMobileMenu()}>
											<HeaderLogo
												scrolled={scrolled}
												hasAtf={pageHasAtf}
												mobileMenuOpen={mobileMenu}
											/>
											</a>
										</Link>
									</LogoCol>
									<div>
										<NavLinks alignment="right">
											<ResponsiveComponent
												small={
													<li>
														<MenuIcon
															id="mobile-cart-icon"
															// onClick={!cartLink ? toggleCart : () => false}
															// to={cartLink}
															// as={cartLink ? Link : 'a'}
                              // onClick={toggleCart}
                              as='a'
															onClick={toggleCart}
														>
															<CartIcon
																count={cartCount}
																scrolled={scrolled}
																mobileMenuOpen={mobileMenu}
															/>
														</MenuIcon>
													</li>
												}
												medium={
													<Fragment>
														{rightNavigation &&
															rightNavigation.map((link, index) => {
																let to = '/'
																let active = false
																if (link?.to?.shopifyProductId) {
																	to = getProductLink(link.to.shopifyProductId)
																} else if (link.externalLink) {
																	to = link.externalLink
																} else if (link?.to) {
																	to = '/' + link.to[0].slug
																	active = pathname === link.to[0].slug
																}
																return (
																	<li key={'header-link-right' + link.id}>
																		<NavLink
																			scrolled={scrolled}
																			hasAtf={pageHasAtf}
																			to={to}
																			active={active}
																			key={to + '-right'}
																			hasDropdown={link.dropdownLinks}
																		>
																			{link.label}
																		</NavLink>
																		<NavLinkUnderline
																			active={pathname === link.to[0].slug}
																		/>
																		{link.dropdownLinks && (
																			<Dropdown>
																				{link.dropdownLinks.map(
																					(dropdownLink, index) => (
																						<li key={dropdownLink.id}>
																							<Link
																								to={'/' + dropdownLink.to[0].slug}
																							>
																								{dropdownLink.label}
																							</Link>
																						</li>
																					)
																				)}
																			</Dropdown>
																		)}
																	</li>
																)
															})}
														<li>
															<MenuIcon
																id="mobile-cart-icon"
																// onClick={!cartLink ? toggleCart : () => false}
																// to={cartLink}
																// as={cartLink ? Link : 'a'}
																// external={cartLink}
                                as='a'
                                onClick={toggleCart}
															>
																<CartIcon count={cartCount} scrolled={scrolled} />
															</MenuIcon>
														</li>
													</Fragment>
												}
											/>
										</NavLinks>
									</div>
								</HeaderContent>
								<StyledRoughEdge
									position="bottom"
									scrolled={scrolled}
									mobileMenuOpen={mobileMenu}
								/>
							</HeaderWrapper>
						</Wrapper>
					)
				}}
			</ScrollListener.Consumer>

			{!hasAtf && placeholder && (
				<HeaderPlaceholder hasBanner={bannerText && bannerVisible} />
			)}

			<ResponsiveComponent
				small={
					<MobileMenu
						open={mobileMenu}
						allShopifyProduct={allShopifyProduct}
						toggleMobileMenu={toggleMobileMenu}
						closeMobileMenu={closeMobileMenu}
						navLinks={allNavigation}
						pathname={pathname}
						// footerColumn1={footerColumn1}
						// footerColumn2={footerColumn2}
					/>
				}
				medium={<span />}
			/>
		</Fragment>
	)
}

export default Header
